
.login-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 289px);

  h1 {
    padding-top: 50px;
    align-self: center;
    font-size: 1.5rem;
  }

  .login {
    margin: 60px auto;
    color:black;
    height: 100%;
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ecf5f0;
    border: 6px solid #00000085;
  
    h1 {
      color:white;
      font-size: 35px;
    }
  
    &__form {
    padding: 30px;
    position: relative;
    
      legend{
        color: #83bffb;
  
        img {
          height: 22px;
          margin-top: -5px;
        }
      }
  
      input {
        width: 100%;
        margin: 5px 0;
      }
  
      .submit {
        text-align: right;
        width: 100%;
        right: 30px;
        padding: 20px 0 0 0;
  
        button {
          width: 130px;
          border-radius: 0px;
  
          img {
            height: 15px;
            margin-right: 15px;
          }
        }
      }
    }
  
    .forgot_password {
      color: #f8ff13f5;
      text-align: left;
      border-radius: 0px;
      border-top: 4px solid #8e8b8b;
  
      img {
        height: 22px;
      }
    }
  }
}
