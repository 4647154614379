.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 0 0 10px 10px; 

  img {
    height: 140px;
    width: auto;
    margin: 20px;
  }

  img.lang {
    height: 20px; 
    width: auto;
    margin: 0;
  }

  .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ul {
      display: flex;
      padding-right: 40px;
      
      li {
        list-style: none;

        a {
          color: #928972;
          padding: 2px 10px;
          font-size: 18px;
          font-weight: 800;

          &.active {
            color: #101023;
          }

          &:hover {
            color: #928972;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header {
    width: 100%;
    flex-direction: column;
    text-align: center;

    .menu {
      ul {
        justify-content: center;

        li a {
          font-size: 14px;
        }
      }
    }

    a {
      padding: 0 50px 0;
      
      img {
        width: 100%;
        height: auto;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
