.home {
  padding: 50px 0;

  .home-subtitle {
    font-size: 20px;
  }
} 

.cards {
  margin-bottom: 30px;
}

@include media-breakpoint-down(md) {
  .cards .col-md-4 {
    margin-bottom: 15px;
  }
}

