.footer {
  background-color: white;
  color: black;
  width: 100%;
  height: 100%;
  padding: 20px 0 0;
  font-size: 14px;
  border-radius: 10px 10px 0 0;

  a {
    color: #928972;
  }

  .footer-title {
    font-weight: bold;
    font-size: 20px;
    padding-left: 90px;
    display: block;
    margin: 50px 0 20px;
  }

  .footer-menu {
    display: flex;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #f4f5f6;
    border-radius: 5px 5px 0 0;

    a {
      font-size: 12px;
      margin-right: 10px;
      color: #928972 !important;
    }
  }

  .footer-menu ul { 
    margin-bottom: 0;
    li {
      display: flex;
      align-items: flex-end;
    } 
  } 

  .image-links {
    display: flex;
    justify-content: space-evenly;

    .footer-menu {
      ul {
        list-style: none;
        display: flex;
        padding: 0;

        li {
          padding: 0 10px;

          a {
            color: darkblue;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .footer-image {
    img {
      width: auto;
      height: 150px;
    }
  }

  a {
    color: inherit;
  }
}

@include media-breakpoint-down(md) {

  .footer {
    overflow: hidden;
    justify-content: center;
    flex-direction: column;

    .address-btw,
    .phone-iban,
    .email {
      padding-left: 20px;
    }

    .image-links {
      flex-direction: column;
    }
    
    .footer-image {
      margin: auto;
    }
  }
}
