.contact {
  padding-top: 50px;
  padding-bottom: 50px;

  h1,
  h2 {
    font-size: 40px;
    margin-bottom: 40px;
  }

  &__form{
    margin: 0;

    .row {
      margin: 5px 0;
    }

    label {
      margin: 5px 0;
    }

    .form-title {
      font-size: 22px; 
    }

    .form-subtitle {
      font-style: italic;
    }

    input {
      border-bottom: 2px solid white;
      background-color: #FFF;
      border-radius: 5px;
      height: 40px;
      padding: 5px 10px;
    }

    textarea {
      background-color: #FFF;
      border-radius: 5px;
    }

    input,
    textarea {
      display: flex;
      color: #000;
      border-left: none;
      border-right: none;
      border-top: none;
      width: 100%;
  
      &::placeholder {
          color:#000;
          margin-top:50px;
      }
    }

    select {
      width: 100%;
      height: 40px;
      padding: 5px 10px;
      border-radius: 5px;
    }

    input[type=submit] {
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      padding: 5px 10px;
      margin-top: 20px;
      background-color: #928972;
      color: #FFF;
      opacity: 1;
      transition: 200ms ease;
      border: 0;

      &:hover {
        opacity: .8;;
        color: #FFF;
        border-color: black;
        cursor: pointer;
      }
    }
  }

  &__calamity {
    margin-top: 60px;

    .calamity-title {
      font-size: 15px;
      font-style: italic;
      border-bottom: 3px solid white;
      padding-left: 10px;
    }

    .calamity-subtitle {
      font-size: 25px;
      padding-top: 50px;
    }
  }
}
