@import '../../vendor/twbs/bootstrap/scss/bootstrap.scss';
@import '../../vendor/twbs/bootstrap/scss/mixins/breakpoints';
@import '../../vendor/components/font-awesome/scss/fontawesome.scss';
@import 'assets/scss/contact.scss';
@import 'assets/scss/home.scss';
@import 'assets/scss/login.scss';
@import 'assets/scss/header.scss';
@import 'assets/scss/footer.scss';

body {
  background-image: url('/assets/images/background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 800;
}

.h1, h1 {
  font-size: 2rem;
}

.h2, h2 {
  font-size: 1.6rem;
}

.h3, h3 {
  font-size: 1.2rem;
}

.container {
  color: white;
}

.card {
  color: #000;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
  }
  a {
    color: #928972;
  }
}

.footer .container {
  color: #000 !important;
}

p {
  margin-bottom: 20px;
  line-height: 140%;
}